export const language = process.env.NEXT_PUBLIC_APP_LANGUAGE;
export const market = process.env.NEXT_PUBLIC_APP_MARKET;
export const locale = process.env.NEXT_PUBLIC_APP_LOCALE;

const config: {
  language: string;
  market: string;
  locale: string;
} = {
  language,
  market,
  locale,
}

export default config;
