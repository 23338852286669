import getSearchEngineUrl from '@utils/getSearchEngineUrl';

type Loop54EventEntityAttribute = {
  name: string;
} & (
  | { type: 'string' | 'date'; values: string[] }
  | { type: 'number' | 'integer'; values: number[] }
  | { type: 'boolean'; values: boolean[] }
);

type Loop54EventEntity = {
  type: 'Product' | 'Variant';
  id: string;
  attributes?: Loop54EventEntityAttribute;
};

type Loop54Event = {
  entity: Loop54EventEntity;
} & (
  | {
      type: 'click' | 'addtocart';
      revenue?: never;
      orderId?: never;
      quantity?: never;
    }
  | {
      type: 'purchase';
      revenue: number;
      orderId: string;
      quantity: number;
    }
);

type CreateEventPayload = {
  events: Loop54Event[];
  customData?: any;
};

type CreateLoopEventProps = {
  payload: CreateEventPayload;
  searchEngineUser: string;
};

export default async function createLoopEvent({
  payload,
  searchEngineUser,
}: CreateLoopEventProps) {
  const url = `${getSearchEngineUrl()}/createEvents`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Api-Version': 'V3',
      'User-Id': searchEngineUser,
    },
    body: JSON.stringify(payload),
    cache: 'no-store',
  });

  if (!response.ok) {
    const error = await response.text();

    console.error(`createLoopEvent - Failed to create loop event: ${error}`);
  }

  return response;
}
