import { getCookie } from 'cookies-next';
import {
  DEFAULT_LOOP_USER_ID,
  LOOP_USER_ID_COOKIE_NAME,
} from '@lib/config/loop';

export default function getSearchEngineUserClient() {
  const userId = getCookie(LOOP_USER_ID_COOKIE_NAME);

  if (userId && typeof userId === 'string') {
    return userId;
  }

  return DEFAULT_LOOP_USER_ID;
}
